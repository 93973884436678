.benefits-radio .ant-radio-group {
	padding: 15px;
}

.benefits-radio .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    display: none;
}

.benefits-radio .ant-radio-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
	justify-content: space-between;
}
.benefits-radio .radio-content-wrapper .ant-radio-wrapper {
	margin-top: 10px;
}
.benefits-radio .ant-collapse .ant-radio-wrapper {
	align-items: center;
}
.benefits-radio .ant-collapse .ant-radio-wrapper:last-child {
	border-bottom: none;
}
.benefits-radio .ant-radio-wrapper span:nth-child(2) {
	white-space: normal;
	padding-left: 0;
}

.benefits-radio .ant-radio-wrapper span .description {
	font-size: 12px;
}
.benefits-radio .ant-collapse {
	margin-bottom: 5px;
}
.benefits-radio .ant-collapse .ant-collapse-header {
	display: flex;
	justify-content: space-between;
	/* padding-left: 15px; */
	padding-right: 25px;
	/* background: #eaf2ff; */
	align-items: center;
}
.benefits-radio .ant-collapse .ant-collapse-content {
	/* background: #eaf2ff; */
}
.benefits-radio .ant-collapse {
	border-radius: 0;
	border: none;
	border-bottom: 1px solid #cccccc;
}
.benefits-radio .ant-collapse > .ant-collapse-item {
	border-bottom: none;
	background-color: #ffffff;
}
.benefits-radio .ant-collapse-content > .ant-collapse-content-box {
	padding: 20px;
	padding-left:30px;
}
.radio-content-wrapper {
	border-radius: 0;
	border: none;
	border-bottom: 1px solid #cccccc;
}
.benefits-modal .ant-modal-body {
	padding: 0;
	min-height: 100px;
}
.benefits-modal .radio-content-wrapper {
	/* background: #fafafa; */
    margin-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px
}

.benefits-modal .ant-modal-header {
	/* background-image: linear-gradient(to left,#0180c8,#2b2171,#2b2171); */
	padding: 0px;
}
.benefits-modal .ant-modal-header .ant-modal-title {
	color: #fff;
}

.benefits-modal .benefits-collapse-header span {
	font-size: 14px;
	color: rgba(0, 0, 0, 0.65);
	font-weight: normal;
}

.capitalized-text {
	text-transform: capitalize;
}

.benefits-modal .next-btn-container {
	display: flex;
    justify-content: center;
    margin: 20px 0;
    height: 50px;
}
.benefits-modal .next-btn-container .ant-btn-primary {
	width: 116px;
	height: 35px;
	border-radius: 2px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	background-color: #0180c8;
	color: #fff;
}
.benefits-modal .next-btn-container .ant-btn-primary:disabled {
	background-color: #eee !important;
	color: #fff !important;
}
.benefits-modal .ant-modal-close-x {
	color: #fff;
}

.benefits-modal .benefits-radio {
	width: 100%;
}

.benefits-modal .ant-modal-body .ant-spin-nested-loading {
	position: unset;
}
.benefits-modal .ant-modal-body .ant-spin-nested-loading h3 {
	text-align: center;
	padding-top: 40px;
	padding-bottom:40px;
	
}
.benefits-modal .claim-validation-content {
	padding: 30px;
}
.benefits-modal .claim-validation-footer {
	display: flex;
	justify-content: flex-end;
	padding-bottom: 20px;
}
.benefits-modal .claim-validation-footer .yes-btn-claim-validation {
	width: 78px;
	height: 44px;
	border-radius: 2px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	background-color: #0180c8;
	margin-right: 5px;
	color: #fff;
}

.benefits-modal .claim-validation-footer .no-btn-claim-validation {
	width: 78px;
	height: 44px;
	border-radius: 2px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	border: solid 1px #0180c8;
	background-color: #ffffff;
	margin-right: 10px;
	color: #0180c8;
}

.benefits-modal .ant-spin {
	position: absolute;
    display: inline-block;
    opacity: 1;
    left: 43%;
    top: 50%;
}

 /* feedbackForms */
.feedback-radio .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    display: none;
}

.feedback-radio .ant-radio-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
	justify-content: space-between;
}
.feedback-radio .radio-content-wrapper .ant-radio-wrapper {
	margin-top: 10px;
}
.feedback-radio .ant-collapse .ant-radio-wrapper {
	height: 50px;
	align-items: center;
	/* border-bottom: 1px solid #d9d9d9; */
}
.feedback-radio .ant-collapse .ant-radio-wrapper:last-child {
	border-bottom: none;
}
.feedback-radio .ant-radio-wrapper span:nth-child(2) {
	white-space: normal;
	padding-left: 0;
}

.feedback-radio .ant-radio-wrapper span .description {
	font-size: 12px;
}
.feedback-radio .ant-collapse {
	margin-bottom: 5px;
}
.feedback-radio .ant-collapse .ant-collapse-header {
	display: flex;
	justify-content: space-between;
	padding-left: 20px;
	padding-right: 28px;
	background: #eaf2ff;
	align-items: center;
}
.feedback-radio .ant-collapse .ant-collapse-content {
	background: #eaf2ff;
}
.feedback-radio .ant-collapse {
	border-radius: 0;
	border: none;
}
.feedback-radio .ant-collapse > .ant-collapse-item {
	border-bottom: none;
}
.feedback-radio .ant-collapse-content > .ant-collapse-content-box {
	padding: 20px;
}
.feedback-modal .ant-modal-body {
	padding: 0;
	min-height: 100px;
}
.feedback-modal .radio-content-wrapper {
	padding: 20px;
	background: #eaf2ff;
	margin-bottom: 5px;
}

.feedback-modal .ant-modal-header {
	background-image: linear-gradient(to left,#0180c8,#2b2171,#2b2171);
	padding: 16px;
}
.feedback-modal .ant-modal-header .ant-modal-title {
	color: #fff;
}

.feedback-modal .feedback-collapse-header span {
	font-size: 12px;
}
.feedback-modal .next-btn-container {
	display: flex;
    justify-content: center;
    margin: 20px 0;
    height: 50px;
}
.feedback-modal .next-btn-container .ant-btn-primary {
	width: 116px;
	height: 35px;
	border-radius: 2px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	background-color: #0180c8;
	color: #fff;
}
.feedback-modal .next-btn-container .ant-btn-primary:disabled {
	background-color: #eee !important;
	color: #fff !important;
}
.feedback-modal .ant-modal-close-x {
	color: #fff;
}

.feedback-modal .feedback-radio {
	width: 100%;
}

.feedback-modal .ant-modal-body .ant-spin-nested-loading {
	position: unset;
}
.feedback-modal .ant-modal-body .ant-spin-nested-loading h3 {
	text-align: center;
    padding-top: 40px;
}
.feedback-modal .claim-validation-content {
	padding: 20px;
}
.feedback-modal .claim-validation-footer {
	display: flex;
	justify-content: flex-end;
	padding-bottom: 20px;
}
.feedback-modal .claim-validation-footer .yes-btn-claim-validation {
	width: 78px;
	height: 44px;
	border-radius: 2px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	background-color: #0180c8;
	margin-right: 5px;
	color: #fff;
}

.feedback-modal .no-btn-claim-validation {
	width: 78px;
	height: 44px;
	border-radius: 2px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	border: solid 1px #0180c8;
	background-color: #ffffff;
	margin-right: 10px;
	color: #0180c8;
}
.feedback-modal .succes-page-button{
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 #00000033;
  background-color: #0180c8;
  font-family: 'Zilla Slab', serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 35px;
}

.feedback-modal .ant-spin {
	position: absolute;
    display: inline-block;
    opacity: 1;
    left: 43%;
    top: 50%;
}
.feedback-modal .ant-radio-button-wrapper{
  border: none;
}
.feedback-modal .ant-radio-button-wrapper::before{
  background: transparent;
}
.feedback-modal .ant-radio-button-wrapper-checked{
  outline: none !important;
  -webkit-box-shadow: transparent !important;
   box-shadow:none !important;
}
.feedback-modal .ant-radio-button-wrapper-checked::before{
  background: transparent !important;
}
.feedback-modal .ant-radio-button-wrapper-checked::selection{
  background: transparent !important;
}
.feedback-modal .first{
  border-bottom: solid 1px #f6f6f6;
}
.feedback-modal .ant-radio-group{
  margin-top: 30px;
}
.feedback-modal .smiley-text::selection{
  display: block !important ;
}
.feedback-modal .smiley-text{
  display: none;
}
.feedback-modal .ant-form-item-label{
  font-family: Muli;
 font-size: 15px;
 font-weight: normal;
 font-style: normal;
 font-stretch: normal;
 line-height: normal;
 letter-spacing: normal;
 color: #000000;
 white-space: pre-wrap;
 text-align: start;
}
.feedbackModal .ant-checkbox-inner{
  display: none !important;
}
.feedback-modal .ant-checkbox-wrapper{
  border-radius: 2px;
  border: solid 1px #c9c9c9;
  padding: 10px 5px;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-left: 0px;
  margin-right: 8px;
  margin-top: 10px;
}

.feedback-modal .ant-checkbox-inner{
  display: none !important;
}

.feedback-modal .inactive-class{
  background: #ffffff;
}
.feedback-modal .active-class{
  background: #fabf59 !important;
}
.feedback-modal form textarea.ant-input{
  margin-top: 8px;
}
.feedback-modal .ant-btn-primary{
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 #00000033;
  background-color: #0180c8;
  font-family: 'Zilla Slab', serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.feedback-modal.ant-modal-wrap{
  z-index: 102002 !important;
}
.feedback-modal .feedback-smiley{
  width: 50px;
}

@media only screen and (max-width: 768px) {
  .feedback-modal .feedback-smiley{
    width: 35px;
  }
}
