* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html, body {
    font-family: 'Muli', sans-serif !important;
    font-size: 18px !important;
    color: #333333;
    background: #f5f5f5;
}
.vehicle-hero {
    background: url('../image/mobile-home.png');
    background-position: bottom;
    min-height: 100vh;
    height: auto;
    background-repeat: no-repeat;
}

.ant-pagination li {
    margin-left: 0 !important;
}

.ant-select-selection {
    border-radius: 0;
}

.ant-btn-primary:focus {
    background-color: #d6b304;
}
.ant-btn {
    color: #000;
}
.ant-btn:hover{
    color: #000;
}
.ant-form-extra {
    font-size: 10px;
}
.ant-modal .ant-modal-content .ant-modal-body .ant-steps-icon-dot {
    background: #f6d300 !important;
}
.ant-modal .ant-modal-content .ant-modal-body .ant-steps-item-finish > .ant-steps-item-tail:after {
    background-color: #eee !important;
}
.ant-modal .ant-modal-content .ant-modal-body .ant-steps-dot .ant-steps-item-content {
    width: unset;
}
.lang-dropdown {
    position: fixed !important;
    border-radius: 0px !important;
    top: 70px !important;
    background-color: #ebebeb;
}

.ant-select-dropdown-menu-item{
    overflow: unset !important;
    text-overflow: unset !important;
    white-space: break-spaces !important;
}

.ant-select-dropdown-menu-item:first-child, .ant-select-dropdown-menu-item:last-child {
    border-radius: 0px !important;

}

.ant-select-dropdown-menu-item:hover {
    background-color: #ffd500;
}

.ant-notification {
    right: 0px;
    top: 80px !important;
    bottom: auto;
    z-index: 1000000 !important;
}

.initial-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.img-container {
    width: 100%;
}

.shopee-gadget-model .ant-modal-header {
    background-color: #ee4d2d;
}
.shopee-gadget-model .ant-modal-title, .shopee-gadget-model .ant-modal-close-x {
    color: #fff;
}

.shopee-gadget-model .action-button {
    color: #fff;
    background-color: #ee4d2d;
    border-color: #ee4d2d
}

@media screen and (max-width: 770px) {
    .shopee-gadget-model {
        top: auto;
    }
    .shopee-gadget-model .ant-modal {
        width: 100vh;
        max-width: 100%;
        padding: 0;
        margin: 0;
        top: auto;
    }
    .shopee-gadget-model .ant-modal-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .shopee-gadget-model .ant-modal-body{
        max-height: 90vh;
        overflow: auto;
    }
}

